import React from "react";
import { MediaRenderIcons } from "../Icons/Icons";
import ReactPlayer from 'react-player';
import './VideoPlayer.css';

export const VideoPlayer = ({ mediaId, id, src, carouselOpen, flag, setDeleteId ,handleMediaClick }) => {
    const userType = sessionStorage.getItem('userType');

    return (
        <div className="video-container">
            { userType === "host" && <MediaRenderIcons mediaId={mediaId} id={id} flag={flag} setDeleteId={setDeleteId} /> }
            <video id={src} src={src} className="media-video" onClick={() => handleMediaClick(mediaId)}/>
            <i className={`bi ${carouselOpen ? 'bi-pause-fill' : 'bi-play-fill'} play-pause-icon`} onClick={() => handleMediaClick(mediaId)}/>
        </div>
    );
};

export const CarouselVideoPlayer = ({ src }) => {
    return (
        <div className="video-container-carousel">
            <ReactPlayer
                url={src}
                controls
                playing
                width="100%"
                height="100%"
                className="carousel-video"
            />
        </div>
    );
};
