import React, { useEffect, useState } from "react";
import Carousel from "../Carousel/Carousel";
import RenderMediaRows from "../../Utils/RenderMediaRows";
import './MediaRender.css';

function MediaRender ({ media, flag, deleteId, setDeleteId}) {
    const [carouselOpen, setCarouselOpen] = useState(false);
    const [initialCarouselIndex, setInitialCarouselIndex] = useState(0);

    return (
        <div className="album-content">
            <RenderMediaRows media={media} carouselOpen={carouselOpen} flag={flag} setDeleteId={setDeleteId} setInitialCarouselIndex = {setInitialCarouselIndex} setCarouselOpen={setCarouselOpen} />
            {carouselOpen && (
                <Carousel
                    media={media}
                    initialIndex={initialCarouselIndex}
                    setInitialCarouselIndex={setInitialCarouselIndex}
                    onClose={() => setCarouselOpen(false)}
                    setDeleteId={setDeleteId}
                    flag = {flag}
                />
            )}
        </div>
    );
};

export default MediaRender;
