import React, { useEffect, useState } from 'react';
import Header from '../Header/Header';
import MediaRender from '../Albums/MediaRender/MediaRender';
import './PhysicalAlbum.css';
import { fetchPhysicalAlbums } from '../../Scripts/FetchAlbums';
import upArrow from "../../Constant/up-arrow.gif";
import { Toaster } from 'sonner';

const PhysicalAlbum = () => {
    const [media, setMedia] = useState([]);
    const [deleteId, setDeleteId] = useState(null)
    const [showScrollToTop, setShowScrollToTop] = useState(false);
    const [lastScrollY, setLastScrollY] = useState(0);

    const id = sessionStorage.getItem("eventID")

    useEffect(() => {
        fetchPhysicalAlbums(id, setMedia);
        setDeleteId(null)
    }, []);

    useEffect(() => {
      let newMedia = media.filter((_, index) => index !== deleteId);
      setMedia(newMedia)
      setDeleteId(null)
    }, [deleteId]);

    useEffect(() => {
      const handleScroll = () => {
          if (window.scrollY === 0) {
              setShowScrollToTop(false);
          } else if (window.scrollY < lastScrollY) {
              setShowScrollToTop(true);
          } else {
              setShowScrollToTop(false);
          }
          setLastScrollY(window.scrollY);
      };

      window.addEventListener('scroll', handleScroll);
      return () => {
          window.removeEventListener('scroll', handleScroll);
      };
  }, [lastScrollY]);

  const scrollToTop = () => {
      window.scrollTo({ top: 0, behavior: 'smooth' });
  };

  return (
    <div className='physical-albums'>
      <Header />
      <div className="divider"></div>
      <h1 className='heading'>Physical Album</h1>
        {media.length > 0 ? (
            <div>
              <MediaRender media={media} deleteId={deleteId} setDeleteId={setDeleteId} flag="physicalAlbum"/>
              {showScrollToTop && (
                <img 
                    src={upArrow} 
                    alt="Scroll to Top" 
                    className="scroll-to-top-gif" 
                    onClick={scrollToTop}
                />
            )}
            </div>
        ) : (
            <p>No albums available</p>
        )}

        <Toaster richColors />
    </div>
  );
};

export default PhysicalAlbum;
