import React, { forwardRef } from 'react';
import gifURL from '../../../Constant/down-arrow.gif';
import './PageTwo.css';

const PageTwo = forwardRef(({ coverPhotoURL, itemName, description }, ref) => (
  <div className="page-two-wrapper" ref={ref}>
    <div id="page2" className="page-two-container">
      <div className="image-container">
        <img className="image-second" src={coverPhotoURL} alt={itemName} />
      </div>
      <div className="description-container">
        <h2 className="description-heading">About</h2>
        <p>{description}</p>
      </div>
    </div>
    {gifURL && <img src={gifURL} alt="GIF" id="arrow-gif-page2" />} 
  </div>
));

export default PageTwo;
