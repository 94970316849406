import React, { useEffect, useState } from 'react';

import './LandingPage.css';

import PageOne from '../PageOne/PageOne'; 
import PageTwoContent from '../PageTwoContent/PageTwoContent';

import fetchData from '../../../Scripts/FetchData';
import fetchFolderData from '../../../Scripts/FetchFolderData';

function LandingPage() {
    const [itemName, setItemName] = useState(null);
    const [coverPhotoURL, setCoverPhotoURL] = useState(null);
    const [date, setDate] = useState(null);
    const [photographer, setPhotographer] = useState(null);
    const [description, setDescription] = useState('');
    const [folderData, setFolderData] = useState([]);

    const id = sessionStorage.getItem('eventID');
    console.log(id)

    function getPhotographer(items) {
        const photographer = items.find(item => item.category.toLowerCase() === "photography company");
        return photographer ? photographer.name : "Unknown";
    }
  
    useEffect(() => {
        fetchData(id, setItemName, setCoverPhotoURL, setDate, setPhotographer, setDescription, getPhotographer );
        fetchFolderData(id, setFolderData);
    }, []);  


    return (
      <div>
        <PageOne itemName={itemName} coverPhotoURL={coverPhotoURL} date={date} photographer={photographer} />
        <PageTwoContent itemName={itemName} coverPhotoURL={coverPhotoURL} description={description}  eventId={id} folderData={folderData} />
      </div>
    );
}

export default LandingPage;
