import React from 'react';
import { Icon } from '@iconify-icon/react';
import handleAddPhysicalAlbumClick from '../../../Scripts/AddToAlbum';
import handleDeletePhysicalAlbumClick from '../../../Scripts/DeleteFromPhysicalAlbum';
import './Icons.css';

const handleCarouselAddPhysicalAlbumClick = (mediaId, media) => {
    const item = media[mediaId];
    handleAddPhysicalAlbumClick(item.item.id);
};

const handleDeleteClick = (mediaId, id, setDeleteId, check, media, setInitialCarouselIndex, handlePrev, handleNext) => {
    if(check === true){
        if (mediaId === media.length - 1) {
            setInitialCarouselIndex(mediaId - 1);
            handlePrev();
        } 
        else if(mediaId === 0){
            handleNext();
        }
    }
    handleDeletePhysicalAlbumClick(id);
    setDeleteId(mediaId);
};

export const CarouselIcons = ({ mediaId, media, flag, setInitialCarouselIndex, setDeleteId, handlePrev, handleNext}) => {
    return (
        <div className="media-icons">
            {flag === "album" ? (
                <Icon
                    onClick={() => handleCarouselAddPhysicalAlbumClick(mediaId, media)}
                    icon="solar:album-bold-duotone"
                    className="icon-album"
                />
            ) : (
                <Icon
                    onClick={() => handleDeleteClick(mediaId, media[mediaId].item.id, setDeleteId, true, media, setInitialCarouselIndex, handlePrev, handleNext)}
                    icon="akar-icons:trash-can"
                    className="icon-album"
                />
            )}
            {/*<a href={media[mediaId].item.url} download>*/}
            {/*    <Icon icon="prime:download" className="icon-album" />*/}
            {/*</a>*/}
        </div>
    );
};

export const MediaRenderIcons = ({ mediaId, id, flag, setDeleteId, src }) => {
    return (
        <div className="media-render-icons">
            {flag === "album" && 
                <Icon
                    onClick={() => handleAddPhysicalAlbumClick(id)}
                    icon="solar:album-bold-duotone"
                    className="icon-album"
                />
            }
            {flag === "physicalAlbum" &&
                <Icon
                    onClick={() => handleDeleteClick(mediaId, id, setDeleteId, false)}
                    icon="akar-icons:trash-can"
                    className="icon-album"
                />
            }
            {/*<a href={src} download>*/}
            {/*    <Icon icon="prime:download" className="icon-album" />*/}
            {/*</a>*/}
        </div>
    );
};
