import React, { useEffect, useState } from "react";
import { useLocation, useParams } from "react-router-dom";
import Header from "../../Header/Header";
import MediaRender from "../MediaRender/MediaRender";
import {fetchAlbums, fetchAllFiles} from "../../../Scripts/FetchAlbums";
import upArrow from "../../../Constant/up-arrow.gif";
import './Albums.css';
import { Toaster } from "sonner";

function Albums() {
    const [media, setMedia] = useState([]);
    const location = useLocation();
    const { folder } = location.state || {};
    const { albumId , id} = useParams();
    const [deleteId, setDeleteId] = useState();
    const [coverColor, setCoverColor] = useState('');
    const [showScrollToTop, setShowScrollToTop] = useState(false);
    const [lastScrollY, setLastScrollY] = useState(0);
    const colors = [
        "#7F8C8D", "#95A5A6", "#BDC3C7", "#D2D7D3", "#1ABC9C",
        "#16A085", "#2ECC71", "#27AE60", "#3498DB", "#2980B9",
        "#9B59B6", "#8E44AD", "#34495E", "#2C3E50", "#F39C12"
    ];

    useEffect(() => {
        if (albumId === "all") {
            fetchAllFiles(id, setMedia);
        }
        fetchAlbums(albumId, setMedia);
        setCoverColor(colors[Math.floor(Math.random() * colors.length)]);
    }, []);

    useEffect(() => {
        const handleScroll = () => {
            if (window.scrollY === 0) {
                setShowScrollToTop(false);
            } else if (window.scrollY < lastScrollY) {
                setShowScrollToTop(true);
            } else {
                setShowScrollToTop(false);
            }
            setLastScrollY(window.scrollY);
        };

        window.addEventListener('scroll', handleScroll);
        return () => {
            window.removeEventListener('scroll', handleScroll);
        };
    }, [lastScrollY]);

    const scrollToTop = () => {
        window.scrollTo({ top: 0, behavior: 'smooth' });
    };
    return (
        <div className="albums">
            <Header />
            <div className="cover-photo-container" style={{ backgroundColor: coverColor }}>
                {folder.name && <div className="album-name">{folder.name}</div>}
                <div className="cover-photo-gradient"></div>
            </div>
            <MediaRender media={media} flag="album" setDeleteId={setDeleteId} />
            {showScrollToTop && (
                <img 
                    src={upArrow} 
                    alt="Scroll to Top" 
                    className="scroll-to-top-gif" 
                    onClick={scrollToTop}
                />
            )}
            
            <Toaster richColors />
        </div>
    );
}

export default Albums;